@tailwind base;
@tailwind components;
@tailwind utilities;
/* image galery  */
@import "~react-image-gallery/styles/css/image-gallery.css";
.image-gallery-fullscreen-button {
  @apply hidden;
}
.image-gallery-play-button {
  @apply hidden;
}
.image-gallery-left-nav {
  @apply hidden;
}
.image-gallery-right-nav {
  @apply hidden;
}
.image-gallery-thumbnails-container {
  @apply flex justify-between;
}
.image-gallery {
  @apply border border-[#e3e3e3] shadow-md;
}
/* image galery  */

* {
  @apply p-0 m-0 box-border;
  font-family: "Roboto", sans-serif;
}

body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

.container {
  @apply lg:w-[1200px] max-md:px-5 max-sm:px-4  m-auto;
}

/* colors */
.bg__blue {
  @apply bg-[#003049];
}
.bg__yellow {
  @apply bg-[#C9B38C];
}
.text__yellow {
  @apply text-[#C9B38C];
}
.text__black {
  @apply text-[#191919];
}
.text__blue {
  @apply text-[#003049];
}

/* buttons */

.btn__main {
  @apply bg__yellow text-white font-medium text-base py-2 px-6 transition-all duration-200 hover:bg-transparent border hover:border-[#003049] hover:text-[#003049];
}

.btn__secondary {
  @apply border border-[#C9B38C] text-[#C9B38C] font-medium text-base py-2 px-6 hover:bg-[#C9B38C] hover:border-[#C9B38C] hover:text-white;
}
.text__body {
  @apply font-normal text-base text-white;
}
.text__header {
  @apply text-white font-bold;
}
/* inputs */
.my__input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.my__input:focus {
  @apply ring-0 border border-[#003049];
}
.text__black {
  overflow: hidden; /* Hide any overflowing content */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  max-height: 100px; /* Set a maximum height for the text container */
}

.headerlangs {
  position: absolute;
  left: -100px;
  background: #fff;
  padding: 20px;
  border: 1px solid #e3e3e3;
  top: 100%;
  z-index: 100;
}
.headerlangs__li {
  list-style: none;
}
.image-gallery-slide {
  @apply !h-[500px] !flex !items-center !justify-center overflow-hidden max-md:!h-[400px];
}
.image-gallery-slide > img {
  @apply !w-full !object-contain;
}
.image-gallery-thumbnail-inner {
  @apply !h-24;
}
.image-gallery-thumbnail-inner > img {
  @apply !w-full !object-contain object-center;
}

/* .homeComponent .slick-track {
  width: 100% !important;
} */
/* .homeComponent .slick-track .slick-slide {
  width: 100% !important;
  height: 100% !important;
} */

.quill-content ol,
.quill-content ul {
  margin: 0 0 1em 1.5em; /* Alt margini 1em, sol marginini isə 1.5em təyin edir */
  padding: 0; /* İç boşluğu 0 təyin edir */
}

.quill-content ol {
  list-style-type: decimal; /* Ol-ləri ədədi göstərəcək */
}

.quill-content ul {
  list-style-type: disc; /* Ul-ləri nöqtə ilə göstərəcək */
}

.quill-content ol li,
.quill-content ul li {
  margin-bottom: 0.5em; /* Hər bir elementin alt margini */
}

.quill-content {
  /* Quill tərəfindən yaradılan məzmunun daha yaxşı görünməsi üçün əlavə stil */
  p {
    margin: 0 0 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0 10px;
    font-weight: bolder !important;
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }

  .ql-size-small {
    font-size: 0.75em; /* Məzmunu kiçiltmək üçün */
  }

  .ql-size-large {
    font-size: 1.5em; /* Məzmunu böyütmək üçün */
  }

  .ql-size-huge {
    font-size: 2.5em; /* Məzmunu daha da böyütmək üçün */
  }

  .ql-align-center {
    text-align: center;
  }
}
